/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  gtm: 'GTM-5LTJH9W',
  gtmCountry: 'GTM-5RX3PBK',
  completeProfileInputs: ['phoneNumber'],
  id: 'ck5zhzijtteyv0b62dmued8k2',
  dvp4m: false,
  gsv: null,

  appLink: null,
  didomi: null,
};
